<template>
  <div v-if="showTopMenu" class="menu-complete">
    <f-top-app-bar :class="{ 'top-bar': true, 'mobile': $isMobile() }" title="Firma Digital"
                   :img="logo"
                   @clickNavigationIcon="openDrawer"
    >
      <img slot="centerMobile"
           :src="logo"
           alt="logo"
      >
      <template slot="left">
        <img slot="left" :src="logo"
             alt="logo"
             style="cursor:pointer;"
             @click="toHome()"
        >
        <f-top-app-bar-separator />
        <span class="desktop-title">Plataforma Firma Digital</span>
      </template>
      <template slot="right">
        <f-button text @click="onInfoLegal">
          Políticas de Privacidad
        </f-button>
        <f-top-app-bar-separator />
        <f-menu direction="bottom-end">
          <f-button slot="activator" text
                    icon="user" trailicon="angle-down"
          >
            {{ username }}
          </f-button>
          <f-list-item v-if="isUsuarioExterno" text="Cambiar contraseña" @click="onPassChange" />
          <f-list-item text="Cerrar sesión" @click="onLogout" />
        </f-menu>
      </template>
      <template slot="rightMobile">
        <!-- <f-icon class="item" name="key" @click="onPassChange" />
        <f-menu class="item" direction="bottom-end">
          <f-icon slot="activator" class="item" name="key" />
          <f-list-item text="Cambiar contraseña" @click="onPassChange" />
        </f-menu> -->
        <f-icon class="logout-item" name="sign-out-alt" @click="onLogout" />
        <!-- <f-menu class="item" direction="bottom-end">
          <f-icon slot="activator" class="item" name="user" />
          <f-list-item text="Cerrar sesión" @click="onLogout" />
        </f-menu> -->
      </template>
    </f-top-app-bar>
    <f-menu-horizontal ref="menuHorizontalRef"
                       class="f-menu-horizontal"
                       fixed
                       :items-menu="itemsMenu"
                       :open-drawer="openDrawerData"
                       @closeDrawer="closeDrawer"
                       @resize="onResize"
    />
  </div>
</template>

<script>
import { FTopAppBar, FTopAppBarSeparator, FMenuHorizontal, FMenu, FButton, FIcon, FListItem } from 'fwkc4-vue'
import InfoLegal from './dialogs/infoLegal/InfoLegal.vue'
import UserChangePasswordDialog from './dialogs/users/UserChangePasswordDialog.vue'
import logoHorizontal from '../assets/logo_horizontal.png'
import { mapGetters } from 'vuex'

export default {
  name: 'MenuComplete',
  components: {
    FTopAppBar,
    FTopAppBarSeparator,
    FMenuHorizontal,
    FButton,
    FIcon,
    FMenu,
    FListItem
  },
  props: {
    username: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      logo: logoHorizontal,
      openDrawerData: false
    }
  },
  computed: {
    ...mapGetters('user', [
      'isAdmin',
      'isUsuarioExterno',
      'isSindicato',
      'isAdministradorUsuarios'
    ]),
    itemsMenu () {
      const items = []
      if (this.isSindicato) {
        items.push({
          name: 'Consulta de Documentos',
          items: [{
            name: 'Todos',
            action: () => this.sendTabEvent('Todos')
          }]
        })
      } else if (!this.isAdministradorUsuarios) {
        items.push({
          name: 'Consulta de Documentos',
          items: [{
            name: 'Pendientes',
            action: () => this.sendTabEvent('Pendientes')
          }, {
            name: 'Todos',
            action: () => this.sendTabEvent('Todos')
          }]
        })
      }
      if (this.isAdmin) {
        items.push({
          name: 'Mantenimiento',
          items: [{
            name: 'Usuarios Externos',
            action: () => this.sendTabEvent('UsuariosExternos')
          }, {
            name: 'Flujos',
            action: () => this.sendTabEvent('Flujos')
          }]
        })
      } else if (this.isAdministradorUsuarios) {
        items.push({
          name: 'Mantenimiento',
          items: [{
            name: 'Usuarios Externos',
            action: () => this.sendTabEvent('UsuariosExternos')
          }]
        })
      }
      return items
    },
    showTopMenu () {
      return this.$store.getters['ui/showTopMenu']
    }
  },
  methods: {
    openDrawer () {
      this.openDrawerData = true
    },
    closeDrawer () {
      this.openDrawerData = false
    },
    onPassChange () {
      this.$store.dispatch('ui/openDialog', {
        component: UserChangePasswordDialog
      })
    },
    onInfoLegal () {
      this.$store.dispatch('ui/openDialog', {
        component: InfoLegal
      })
    },
    onLogout () {
      this.$emit('logout')
    },
    onResize (event) {
      this.$emit('resize', event)
    },
    toHome () {
      this.$router.push('/').catch((err) => { return err })
    },
    sendTabEvent (tabName) {
      const event = new CustomEvent('eventOpenTab', {
        detail: {
          tabName: tabName
        }
      })
      document.dispatchEvent(event)
    }
  }
}
</script>

<style lang="scss">
.menu-complete {
  z-index:3;
  .logout-item {
    font-size: 35px !important;
    margin-right: 5px;
  }
  .f-menu-horizontal {
    margin-top: 50px;
    z-index:3 !important;
    .menu-desktop__fixed {
      z-index:3 !important;
      .menu-desktop__left,
      .menu-desktop__right {
        z-index:3 !important;
      }
    }
  }
  .desktop-title {
    margin-left: 5px;
    font-size: 20px;
    font-weight: 600;
    color: var(--f-color-primary);
    text-transform: none;
  }
}
.top-bar {
  border-bottom: 1px solid var(--f-color-grey-4);
  &.mobile {
    .mdc-top-app-bar__section--align-start {
      display: none;
    }
    .mdc-top-app-bar__section--align-center-absolute {
      justify-content: flex-start;
      padding-left: 10px;
    }
  }
}
</style>
