/**
 * La store 'user' almacena datos relacionados con el usuario.
 */

import User from '../../models/user'
import { Module } from 'vuex'
import { StateUser } from './types'
import { RootStore } from '@/store/rootStore'
import CurrentUserDTO from '@/api/auth/dto/CurrentUserDTO'

export const user: Module<StateUser, RootStore> = {
  namespaced: true,

  state: {
    // instancia usuario
    user: new User()
  },

  mutations: {
    /**
      * establece o desestablece un usuario
      * @param state - objeto vuex
      * @param param - respuesta de la API del usuario logueado
      */
    setUser (state, param: CurrentUserDTO | null) {
      // si se va a loguear y el usuario a loguear
      // es diferente del de la sesión y del estado
      // entonces añadir el nuevo usuario
      if (param) {
        state.user.setData(param)
        state.user.saveData()
        return
      }
      // si `param` es null, entonces se trata de un deslogueo
      // resetear `user` del estado
      if (!param) {
        state.user.removeData()
      }
    },
    setUsername (state, username) {
      state.user.username = username
    }
  },
  actions: {

    /**
      * desloguea un usuario de la store
      * @param param0
      */
    logoutUser ({ commit }) {
      commit('setUser', null)
    },
    /**
        * loguear un usuario haciendo
        * emitiendo un commit al mutation `setUser` de esta misma store
        * @param param0 - es un objeto de Vuex
        */
    loginUser ({ commit }, params) {
      commit('setUser', params.params)
    },
    /**
     * Solo nos guardamos el username para algunas peticiones que lo necesitan
     * y que es posible que acaben antes que la petición "me"
     * @param param0
     * @param param1
     */
    setUsername ({ commit }, username) {
      commit('setUsername', username)
    }
  },
  getters: {
    // obtiene el usuario
    getUser (state) {
      return state.user
    },
    // obtiene el nombre del usuario
    getUsername (state) {
      return state.user?.username
    },
    // obtiene el perfil del cliente
    getProfile (state) {
      return state.user?.profiles?.[0]
    },
    getProfiles (state) {
      return state.user?.profiles
    },
    isAdmin (state) {
      return state.user?.isAdmin()
    },
    isAdminFlujo (state) {
      return state.user?.isAdminFlujo()
    },
    isUsuarioInterno (state) {
      return state.user?.isUsuarioInterno()
    },
    isUsuarioExterno (state) {
      return state.user?.isUsuarioExterno()
    },
    isSindicato (state) {
      return state.user?.isSindicato()
    },
    isResponsableRRHH (state) {
      return state.user?.isResponsableRRHH()
    },
    isADCO (state) {
      return state.user?.isADCO()
    },
    isAdministradorUsuarios (state) {
      return state.user?.isAdministradorUsuarios()
    },
    isConsultaFlujos (state) {
      return state.user?.isConsultaFlujos()
    },
    hasPanicButton (state) {
      return state.user?.hasPanicButton()
    },
    hasActs (state) {
      return state.user?.hasActs()
    },
    getUserAllowedFlows (state) {
      return state.user?.getUserAllowedFlows()
    },
    getAttributes (state) {
      return state.user?.attributes?.['550']
    }
  }
}

export default user
