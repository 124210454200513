
import Vue from 'vue'
import BasicDialog from '@/components/dialogs/BasicDialog.vue'
import TextArea from '@/components/textArea/TextArea.vue'
import DetailTabs from '@/components/DetailTabs.vue'
import cancelTransaction from '@/services/transactions/cancelTransaction.management'
import { mapGetters } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators'
import { FButton } from 'fwkc4-vue'
import GenericErrorDialog from '@/views/common/dialogs/GenericErrorDialog.vue'
import GenericConfirmedDialog from '@/views/common/dialogs/GenericConfirmedDialog.vue'
import GenericControlConfirmDialog from '@/views/common/dialogs/GenericControlConfirmDialog.vue'
import updateTransaction from '@/services/transactions/updateTransaction.management'

export default Vue.extend({
  name: 'DocumentDetails',
  components: {
    BasicDialog,
    FButton,
    TextArea,
    DetailTabs
  },
  props: {
    userFinishedDocumentCb: {
      type: Function,
      default: null
    },
    from: {
      type: String,
      default: 'PENDIENTES'
    }
  },
  data () {
    const usuario = this.$store.getters['user/getUser']
    return {
      // data
      change: false,
      onPanicMode: false,
      rejectReason: null,
      errorMessages: {
        required: 'El campo es requerido',
        maxLength200: 'Máximo 200 caracteres'
      },
      anexos: null,
      adcoData: null,
      usuario: usuario,
      hasChanges: false
    }
  },
  computed: {
    ...mapGetters('user', [
      'hasPanicButton',
      'getUsername',
      'isAdmin',
      'isADCO'
    ]),
    ...mapGetters('transactionDetail', [
      'getCurrentTransaction'
    ]),
    showPanicButton () {
      return this.hasPanicButton && this.getCurrentTransaction?.estado?.estadoInterno === 'PENDIENTE' && !this.onPanicMode
    },
    showErrorLabel () {
      return this.$v.rejectReason.$invalid
    },
    getShowADCO () {
      const flowID = this.getCurrentTransaction.flujo_id
      if (this.isAdmin === false && this.isADCO === false) return false
      if (flowID !== 93 && flowID !== 95 && flowID !== 103 && flowID !== 125) return false
      if (this.getCurrentTransaction?.estado?.estadoInterno === 'RECHAZADO') return false
      return true
    },
    getErrorLabel  () {
      if (this.rejectReason?.length <= 0) return 'El campo es requerido'
      if (this.rejectReason?.length > 200) return 'Máximo 200 caracteres'
      return ''
    },
    canAddDocument () {
      const perfilesPermitidos = ['UPFI0001', 'UPFI0002', 'UPFI0003', 'UPFI0007', 'UPFI0009']
      const isIncluded = perfilesPermitidos.some(element => this.usuario.profiles.includes(element))
      return this.getCurrentTransaction && this.getCurrentTransaction.flujo_id !== 131 && this.getCurrentTransaction.flujo_id !== 139 && isIncluded
    },
    showButtons () {
      return this.getShowADCO || this.canAddDocument
    },
    getTitle () {
      return this.onPanicMode ? 'Motivo de rechazo' : 'Detalles de la petición'
    }
  },
  validations (): any {
    return {
      rejectReason: {
        required: required,
        maxLength200: maxLength(200)
      }
    }
  },
  async mounted () {
    this.subscribeEvents()
    try {
      this.$store.dispatch('ui/showMask')
      this.setInitialData()
    } catch (err) {
      console.log(err)
    } finally {
      this.$store.dispatch('ui/hideMask')
    }
  },
  methods: {
    onChangeAnexos (data) {
      this.hasChanges = true
      this.anexos = data
    },
    onChangeAdco (data) {
      this.hasChanges = true
      this.adcoData = data
    },
    onCancelChanges () {
      this.$store.dispatch('ui/openDialog', {
        component: GenericControlConfirmDialog,
        props: {
          text: 'Si cancelas se perderán los cambios, ¿deseas continuar?',
          secondModal: true,
          functionCb: () => {
            console.log('Operacion cancelada')
            this.onCloseDialog()
          }
        }
      })
    },
    onConfirmSaveChanges () {
      this.$store.dispatch('ui/openDialog', {
        component: GenericControlConfirmDialog,
        props: {
          text: 'Se guardarán los cambios, ¿deseas continuar?',
          secondModal: true,
          functionCb: () => {
            this.saveChanges()
          }
        }
      })
    },
    saveChanges () {
      this.$store.dispatch('ui/showMask')
      const transaction = this.getCurrentTransaction
      let documentsToSend = null
      // Gestión datos ADCO
      if (this.getShowADCO) {
        transaction.metadatos.Observaciones = this.adcoData.obs || ''
        transaction.metadatos.Observaciones_surtido = this.adcoData.obsAssortment || ''
        transaction.metadatos.Observaciones_proveedor = this.adcoData.obsProvider || ''
        transaction.metadatos.Tratamiento = this.adcoData.treatment || ''
      }
      // Gestión datos Anexos
      if (this.canAddDocument) {
        documentsToSend = this.anexos.filter(item => item.markForDeletion || item.pseudo_id)
        documentsToSend.map(item => delete item.pseudo_id)
      }

      const transactionUpdate = {
        transaction_id: transaction.id,
        user: this.usuario.username,
        request: {
          history: transaction.historico || false,
          year: transaction.year || null,
          metadatos: transaction.metadatos,
          documents: documentsToSend
        }
      }
      updateTransaction(transactionUpdate).then(response => {
        this.$store.dispatch('ui/hideMask')
        this.$store.dispatch('ui/openDialog', {
          component: GenericConfirmedDialog,
          props: {
            text: 'Los cambios se han guardado correctamente.',
            functionCb: () => {
              this.$root.$emit(`refreshTransactionsGrid${this.$props.from}`)
              this.onCloseDialog()
            }
          }
        })
      }).catch(err => {
        this.$store.dispatch('ui/hideMask')
        this.$store.dispatch('ui/openDialog', {
          component: GenericErrorDialog,
          props: {
            text: err.message
          }
        })
      })
    },
    subscribeEvents () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const me = this
      document.addEventListener('EIS01ReceiveMessage', function (e: any) {
        me.onEIS01ReceiveMessage(e.detail.result)
      })
    },
    onEIS01ReceiveMessage (result: string) {
      if (result !== 'REJECTED') {
        this.onCloseDialog()
        this.userFinishedDocumentCb(result === 'REJECTEDMSG')
      }
    },
    setInitialData () {
      this.change = false
      this.hasChanges = false
      this.anexos = this.getCurrentTransaction.documents
      this.adcoData = {
        obs: this.getCurrentTransaction.metadatos.Observaciones,
        obsAssortment: this.getCurrentTransaction.metadatos.Observaciones_surtido,
        obsProvider: this.getCurrentTransaction.metadatos.Observaciones_proveedor,
        treatment: this.getCurrentTransaction.metadatos.Tratamiento
      }
    },
    onPanicButton () {
      this.onPanicMode = true
    },
    onCancel () {
      // confirm first
      this.$store.dispatch('ui/openDialog', {
        component: GenericControlConfirmDialog,
        props: {
          text: 'Si cancelas no se rechazará el documento, ¿deseas continuar?',
          secondModal: true,
          functionCb: () => {
            this.onNoPanicMode()
          }
        }
      })
    },
    onNoPanicMode () {
      this.onPanicMode = false
    },
    async onRejectTransaction () {
      this.$store.dispatch('ui/showMask', {
        text: ' Cargando...'
      })
      let response = null
      try {
        const transaction = this.getCurrentTransaction
        const params = {
          user: this.getUsername,
          transaction_id: transaction.id,
          message: this.rejectReason
        }
        response = await cancelTransaction(params)
        if (response?.status === 'CANCELLED') {
          this.$store.dispatch('ui/openDialog', {
            component: GenericConfirmedDialog,
            props: {
              text: 'El documento se ha rechazado correctamente',
              functionCb: this.onRejectTransactionDone
            }
          })
        }
      } catch (err) {
        console.log(err)
        this.$store.dispatch('ui/openDialog', {
          component: GenericErrorDialog,
          props: {
            text: 'Se ha producido un error, inténtalo de nuevo más tarde'
          }
        })
      } finally {
        this.$store.dispatch('ui/hideMask')
      }
    },
    onRejectTransactionDone () {
      this.$root.$emit(`refreshTransactionsGrid${this.$props.from}`)
      this.onCloseDialog()
    },
    onCloseDialog () {
      this.onPanicMode = false
      this.$emit('closed')
    }
  }
})
