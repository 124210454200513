import { render, staticRenderFns } from "./DocumentData.vue?vue&type=template&id=3543002f&scoped=true&name=content"
import script from "./DocumentData.vue?vue&type=script&lang=ts"
export * from "./DocumentData.vue?vue&type=script&lang=ts"
import style0 from "./DocumentData.vue?vue&type=style&index=0&id=3543002f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3543002f",
  null
  
)

export default component.exports